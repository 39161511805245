import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { forScreen } from '../styles-global/styled-components/styled-responsive'
import { shadowPrimaryWithHover } from '../styles-global/styled-components/styled-mixins'

import MoviePosterImage from './moviePosterImage'

const DocumentEducationListItemCard = ({ item }) => {
  const {
    title,
    description,
    iconImage,
    slug: { current: slug },
  } = item

  const iconImageFluid = iconImage?.asset?.fluid

  return (
    <StyledArticle>
      <MoviePosterImage
        className="iconImage"
        img={iconImageFluid} // if no image given - passes undefined - handled by the component
      />
      <h2 className="title">
        <Link className="title__link" to={slug}>
          {title}
        </Link>
      </h2>
      <p className="description">{description}</p>
    </StyledArticle>
  )
}

const StyledArticle = styled.article`
  flex: 1 1 40rem;

  position: relative;

  display: grid;

  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto 1fr;

  /* shadow */
  ${shadowPrimaryWithHover}

  .iconImage {
    /* RESPONSIVE */
    grid-column: 1;
    grid-row: 1;

    margin-left: 1rem;
    margin-top: 1rem;

    ${forScreen.sm`
      grid-column: 1;
      grid-row: 1 / 3;

      margin-left: 0;
      margin-top: 0;
    `}
  }

  .title,
  .description {
    margin: 1rem;
  }

  .title {
    grid-column: 2;
    grid-row: 1 / 3;

    ${forScreen.sm`
      grid-row: 1;
    `}
  }

  .description {
    grid-column: 1 / 3;
    grid-row: 3;

    border-top: 1px solid var(--grayLight);
    padding-top: 1rem;

    /* RESPONSIVE */
    margin-top: 1rem;

    ${forScreen.sm`
      grid-column: 2;
      grid-row: 2 / 4;

      margin-top: 0;
    `}
  }

  /* to make specificity higher than: a:not(.btn):link, a:not(.btn):visited (0.2.1) */
  .title__link.title__link.title__link {
    color: var(--documentEducationListTitleColor);
  }

  .title__link {
    display: block;

    /* make whole element clickable link */
    &::before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
`
export default DocumentEducationListItemCard
