import React from 'react'
import styled from 'styled-components'
import { flexGap } from '../styles-global/styled-components/styled-mixins'

import SEO from './seo'
import Layout from './layout'
import DocumentEducationListItemCard from './documentEducationListItemCard'

const DocumentEducationList = ({ title, heading, items }) => {
  if (!items || !Array.isArray(items)) {
    //   when not passed items or is not array - set items as an empty array - to prevent items.map error
    items = []
    console.warn(
      `Something is wrong in ${title} items, items not passed or is not array`,
      items
    )
  }

  return (
    <Layout>
      <SEO title={title} />

      <main className="container-xxl">
        <h1>{heading}</h1>
        <ItemsList>
          {items.map(item => (
            <DocumentEducationListItemCard
              item={item}
              key={item.slug.current}
            />
          ))}
        </ItemsList>
      </main>
    </Layout>
  )
}

const ItemsList = styled.div`
  display: flex;
  flex-wrap: wrap;

  /* add default flex gap */
  ${flexGap()}

  margin-bottom: 2rem;
`

export default DocumentEducationList
