import { css } from 'styled-components'

/* 
    * MIXIN FOR FLEX GAP
    workaround add flex gap (still css gap doesn't work on most browsers ) 
    technique: https://coryrylan.com/blog/css-gap-space-with-flexbox

    Can be used (after importing to a component):

    const ItemsList = styled.div`
        // default gap:
        ${flexGap()}  
        ${flexGap``}

        // passed gap size:
        ${flexGap("3rem")}
        ${flexGap`5rem`}

    `
*/
export const flexGap = size => css`
  display: flex;
  flex-wrap: wrap;

  /* default gap size*/
  --gap: 1rem;
  /* set custom gap size */
  --gap: ${size};
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  & > * {
    margin: var(--gap) 0 0 var(--gap);
  }
`

/* 
    * SHADOW MIXINS
    based on https: //codepen.io/sdthornton/pen/wBZdXq

    ! for scss there are the same shadows mixins
    ! in /styles-global/global-variables.js
    ! these should be manually "synchronised" to be the same with scss
*/

export const shadowPrimary = css`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

// shadow used for :hover state
export const shadowSecondary = css`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
`

export const shadowPrimaryWithHover = css`
  ${shadowPrimary}
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    ${shadowSecondary}
  }
`
