import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

// ! Condiser changing name - now it is used in the component documentEducationItem for presenting "Icon image" there

/* 
Component for showing movie poster
Handles situation if there's no img passed (and also if there is no default movie poster in the project)
if passed image should be destructurized fluid (e.g. posterImage.asset.fluid, not just backgroundImage) 

If set className for the component it is passed to the Img component
*/

// default image - used if no image passed to the component
const getDefaultMoviePoster = graphql`
  query {
    defaultPosterImage: file(relativePath: { eq: "movie-poster-default.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const MoviePosterImage = ({ img, className }) => {
  const { defaultPosterImage } = useStaticQuery(getDefaultMoviePoster)

  // handling if not given img & there's no present default movie poster in the project
  if (!(img || defaultPosterImage?.childImageSharp?.fluid)) {
    console.warn('No default poster image!')
    return <></>
  }
  return (
    <Img
      fluid={img || defaultPosterImage.childImageSharp.fluid}
      className={className}
    />
  )
}

export default MoviePosterImage
