import React from 'react'
import { graphql } from 'gatsby'

import DocumentEducationList from '../components/documentEducationList'

const Dokumenty = ({ data }) => {
  const documents = data.allSanityDocumentItem.nodes

  return (
    <DocumentEducationList
      title="Dokumenty"
      heading="Dokumenty Kina Grażyna"
      items={documents}
    />
  )
}

export const query = graphql`
  {
    allSanityDocumentItem(sort: { fields: priorityOnList, order: DESC }) {
      nodes {
        title
        id
        slug {
          current
        }
        description
        iconImage {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default Dokumenty
